import { Link, navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import {useLocation} from '@reach/router';
import qs from 'querystring'
import { HashLoader } from 'react-spinners'
import { theme } from '../../../../tailwind.config';
import Logo from '../../../images/Logo_Primary.svg';

const PasswordResetPage = () => {
    const location = useLocation()
    // const invites = useResetsInvitesContext();
    const parsed = qs.parse(location.search.split("?")[1])
    const { invite, uid } = parsed;
    console.log(invite, uid, parsed)
    const [form, setForm] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
    })
    const onFormChange = (e, field) => {
        setForm({...form, [field]: e.target.value})
    }

    const cb = () => { navigate('/app/dashboard') }

    const onFinish = () => {
        // invites.dispatch({ type: 'invites_post', data: { uid: uid, token: invite, body: form, cb }})
    }

    

    return (
        <div className="grid grid-cols-1 w-full lg:grid-cols-3">
        <div className="lg:col-start-2 lg:col-end-2 mt-8 mb-5 flex justify-center max-h-12">
            <img src={Logo} alt="Kodama"/>
        </div>
        <div className="lg:col-start-2 lg:col-end-2 shadow-lg rounded-xl p-5 border min-h-1/2 border-gray-200">
            {"" === 'LOADING' && (
                <div className="h-72 flex items-center justify-center">
                    <HashLoader color={theme.extend.colors.primary.DEFAULT}/>
                </div>
            )}
            {true && (
                <div className="h-72 flex flex-col">
                    <div>
                        <h2 className="text-xl font-bold">You've been invited to join Kodama</h2>
                        <p className="text-sm">Finish your registration and join your teammates</p>
                    </div>
                    <div className="mt-4">
                        <label htmlFor="firstName"
                            className="text-sm"
                        >
                            First Name
                        </label>
                        <input 
                            name="firstName" 
                            value={form.firstName}
                            onChange={(e) => onFormChange(e, 'firstName')}
                            className="w-full p-1 shadow-inner rounded-sm h-8 border border-gray-300"
                        ></input>
                    </div>
                    <div className="mt-1">
                        <label htmlFor="lastName" className="text-sm">Last Name</label>
                        <input 
                            name="lastName" 
                            className="w-full p-1 shadow-inner rounded-sm h-8 border border-gray-300"
                            value={form.lastName}
                            onChange={(e) => onFormChange(e, 'lastName')}
                        ></input>
                    </div>
                    <div className="mt-1">
                        <label htmlFor="password" className="text-sm">Password</label>
                        <input
                            value={form.password}
                            onChange={(e) => onFormChange(e, 'password')}
                            name="password"
                            type="password"
                            className="w-full p-1 shadow-inner rounded-sm h-8 border border-gray-300"
                        />
                    </div>
                    <div className="flex-1"/>
                    <div className="mt-3 flex w-full justify-end">
                        <div className="w-full">
                            <button
                                onClick={onFinish}
                                className="py-2 px-5 w-full rounded bg-primary text-white"
                            >
                                Complete Registration
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    </div>
    );
};

export default PasswordResetPage;
